<template>
  <div class="box">
    <div class="flex_row m_tb_20">
      <span class="title fs_24 fw_bold">搜索结果</span>
      <div class="line m_lr_10"></div>
      <span class="title result fs_14">
        共有{{ paginationParams.total }}个结果
      </span>
    </div>
    <div
      class="flex_row fs_16 fw_bold m_b_20"
      v-show="this.$route.query.from === '0'">
      <div
        class="classify m_r_30 pointer"
        :class="{ 'select-classify': selectedClassify === 0 }"
        @click="classify_click(0)">
        <div class="m_b_05">
          省级资源（{{ provincialResourceList.length }}）
        </div>
        <div
          class="row-line"
          v-show="selectedClassify === 0"></div>
      </div>
      <div
        class="classify m_r_30 pointer"
        :class="{ 'select-classify': selectedClassify === 1 }"
        @click="classify_click(1)">
        <div class="m_b_05">市级资源（{{ municipalResourceList.length }}）</div>
        <div
          class="row-line"
          v-show="selectedClassify === 1"></div>
      </div>
      <div
        class="classify m_r_30 pointer"
        :class="{ 'select-classify': selectedClassify === 2 }"
        @click="classify_click(2)">
        <div class="m_b_05">区级资源（{{ regionResourceList.length }}）</div>
        <div
          class="row-line"
          v-show="selectedClassify === 2"></div>
      </div>
    </div>
    <div
      class="resource-list flex_row_wrap"
      v-if="resourceList.length !== 0"
      v-loading="loading">
      <SearchResource
        class="m_b_20 resource-item"
        v-for="resource of resourceList"
        :key="resource.id"
        :resourceData="resource" />
    </div>

    <el-empty
      description="暂无数据"
      v-else></el-empty>
    <!--  分页  -->
    <el-pagination
      style="text-align: center"
      class="m_b_20"
      background
      layout="prev, pager, next"
      @current-change="current_change"
      :current-page.sync="paginationParams.currentPage"
      :page-size="paginationParams.size"
      :total="paginationParams.total"
      hide-on-single-page></el-pagination>
  </div>
</template>

<script>
import { sessionGet } from '../../../utils/local';
import { getResourceList } from '../../../api/resourceCenter';
import SearchResource from '../components/resource/SearchResource';
import { mapState } from 'vuex';
export default {
  name: 'Search',
  components: {
    SearchResource,
  },
  data: () => {
    return {
      key: '', //查询信息
      // 省级资源
      provincialResourceList: [],
      // 市级资源
      municipalResourceList: [],
      // 区级资源
      regionResourceList: [],
      resourceList: [],
      // 分页
      paginationParams: {
        currentPage: 1,
        size: 12,
        total: 0,
      },
      resourceParams: {},
      loading: false,
      selectedClassify: 0,
    };
  },
  watch: {
    '$store.state.searchKey': {
      immediate: true,
      handler(val) {
        let searchParams = {
          name: val,
          size: 12,
        };
        this.paginationParams.currentPage = 1;
        this.resourceParams = searchParams;

        this.getResourceList();
      },
    },
    // '$route': {
    //   handler(newVal) {
    //     let searchParams = sessionGet('searchParams')
    //     searchParams.name = newVal.query.keyword
    //     searchParams.size = 12
    //     searchParams.current = 1
    //     this.paginationParams.currentPage = 1
    //     this.resourceParams = searchParams
    //     this.getResourceList()
    //   },
    //   immediate: true
    // }
  },
  methods: {
    getResourceList() {
      this.municipalResourceList = [];
      this.provincialResourceList = [];
      this.regionResourceList = [];
      this.loading = true;
      getResourceList(this.resourceParams).then((res) => {
        let resourceList = res.data.records;
        this.paginationParams.total = res.data.total;
        this.loading = false;

        if (this.$route.query.from !== '0')
          return (this.resourceList = resourceList);

        resourceList.forEach((item) => {
          if (item.level.toString() === '2') {
            this.provincialResourceList = [
              ...this.provincialResourceList,
              ...[item],
            ];
          } else if (item.level.toString() === '3') {
            this.municipalResourceList = [
              ...this.municipalResourceList,
              ...[item],
            ];
          } else if (item.level.toString() === '4') {
            this.regionResourceList = [...this.regionResourceList, ...[item]];
          }
        });

        this.resourceList = this.provincialResourceList;
        this.selectedClassify = 0;
      });
    },
    // 切换分页
    current_change(nowCurrent) {
      this.resourceParams.current = nowCurrent;
      this.getResourceList();
    },
    classify_click(index) {
      this.selectedClassify = index;
      switch (index) {
        case 0:
          this.resourceList = this.provincialResourceList;
          break;
        case 1:
          this.resourceList = this.municipalResourceList;
          break;
        case 2:
          this.resourceList = this.regionResourceList;
          break;
      }
    },
  },
};
</script>

<style scoped>
.box {
  width: 1200px;
  margin: 0 auto;
}

.title {
  line-height: 31px;
}

.line {
  width: 1px;
  height: 14px;
  background-color: rgba(0, 0, 0, 0.2);
  margin-top: 8px;
}

.result {
  color: rgba(0, 0, 0, 0.2);
}

.resource-item {
  margin-right: 16px;
}

.resource-item:nth-child(4n) {
  margin-right: 0;
}

.classify {
  color: rgba(0, 0, 0, 0.4);
}

.select-classify {
  color: rgba(0, 0, 0, 1);
}

.row-line {
  width: 22px;
  height: 4px;
  border-radius: 4px;
  background-color: #6bd9bc;
}
</style>
