<template>
  <div class="recourse-box br_10 bg_fff">
    <router-link :to="{path: '/resource/details', query: {id:resourceData.id}}">
      <img class="img" :src="imgToRespomse(resourceData.coverUrl)" alt="">
      <div class="fs_16 p_in_10 text_hidden fw_bold" v-html="resourceData.name"></div>
      <div class="p_lr_10 fs_14 text_hidden m_b_10" style="color:rgba(0,0,0,0.6);">{{ resourceData.contentNames }}</div>
      <div class="tag fs_14 c_fff flex_row" style="justify-content: center;">
        <img class="m_r_10" :src="typeJSON[resourceData.type].img" alt="" style="height: 14px;margin-top: 6px;">
        <div>{{ typeJSON[resourceData.type].name }}</div>
      </div>
    </router-link>
  </div>
</template>

<script>
import img from '@/assets/image/5g.jpg'
import videoImg from 'assets/image/resource/video.png'
import docImg from 'assets/image/resource/doc.png'
import voiceImg from 'assets/image/resource/voice.png'
import {imgToRespomse} from '../../../../utils/imgToResponse'

export default {
  name: 'SearchResource',
  props: {
    resourceData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      img,
      videoImg,
      //1文档、2音频、3视频
      typeJSON: {
        1: {
          img: docImg,
          name: '文档'
        },
        2: {
          img: voiceImg,
          name: '音频'
        },
        3: {
          img: videoImg,
          name: '视频'
        },
      }
    }
  },
  watch: {
    resourceData: {
      handler(newVal) {
        let keyword = this.$route.query.keyword
        let reg = new RegExp(keyword, 'g')
        this.resourceData.name = newVal.name.replace(reg, `<span style="color:#12C294;">${keyword}</span>`)
      },
      immediate: true
    }
  },
  methods: {
    imgToRespomse(url) {
      return imgToRespomse(url)
    }
  }
}
</script>

<style scoped>
.recourse-box {
  /*width: 290px;*/
  width: 24%;
  overflow: hidden;
  position: relative;
}

.recourse-box:hover {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05),
  0 5px 10px rgba(0, 0, 0, 0.1);
}

.img {
  width: 101%;
  height: 167px;
  object-fit: cover;
}

.tag {
  position: absolute;
  width: 80px;
  background-color: rgba(0, 0, 0, .5);
  text-align: center;
  top: 0;
  right: 0;
  border-radius: 0 10px 0 10px;
  line-height: 26px;
}
</style>